$(document).on('turbolinks:load',function(){

  var $couponForm = $('.js-coupon-form');
  if ($couponForm.length > 0){

    // COUPON FORM SETUP

    var val = $('#coupon_coupon_type').val();
    toggleCouponType(val);
  	$('#coupon_coupon_type').on('change', function(){
  		toggleCouponType($(this).val());
  	});

    function toggleCouponType(val){
      if ( val == 'flat' ) {
        $('.coupon_discount_flat').show();
        $('.coupon_discount_percent').hide();
      } else {
        $('.coupon_discount_flat').hide();
        $('.coupon_discount_percent').show();
      }
    }

    // PRODUCT VARIANT SELECT SETUP
    // TODO merge with components/input_select/input_product_select.js

    $couponForm.on('cocoon:after-insert', function(){
      var $productSelects = $('.js-product-select');
      $.each($productSelects, function(i, el){
        $(el).attr('data-number', i); // set indexes for updating variant selects
      });
      $('.js-select').select2({ width: '100%' }); // re-initialize select2
    });

    $(document).on('change', '.js-product-select', function(){
      var $this = $(this);
      var val = $this.find('option:selected').val();
      var inputNumber = $this.data('number');
      $.ajax({
        type:'GET',
        url: '/product_variants?product=' + val + '&input_number=' + inputNumber,
        dataType : 'script'
      });
    });

    // INCLUDE ALL PRODUCTS SETUP
    var $productsToggle = $('.js-coupon-productsToggle');
    var $productsTarget = $('.js-coupon-productsTarget');

    var updateProductsTarget = function(){
      if ($productsToggle.prop('checked')) {
        $productsTarget.hide();
        // remove any existing products
        $productsTarget.find('.remove_fields').click();
      } else {
        $productsTarget.show();
      }
    }

    $productsToggle.on('change', updateProductsTarget);
    updateProductsTarget();
  }

});
