$(document).on('turbolinks:load', function () {

  // Clean up any pre existing text editors
  $('.js-textEditor-basic').summernote('destroy');
  $('.js-textEditor-expanded').summernote('destroy');
  $('.js-textEditor-slim').summernote('destroy');

  // Initialize basic Summernote text editor
  var basicEditors = $('.js-textEditor-basic');
  for (var i=0; i<basicEditors.length; i++){
    var $basicNote = $(basicEditors[i]);
    $basicNote.summernote({
      height: 140,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']],
      ],
      callbacks: {
        onImageUpload: function(files){
          summernoteImageUpload(files, $basicNote);
        },
        onImageLinkInsert: function(url) {
          insertDefaultWidthImage(url, $basicNote);
        },
        onBlurCodeview: function () {
          // ensure that text entered inside code view is saved without having to switch back to visual mode
          // more info: https://github.com/summernote/django-summernote/issues/127#issuecomment-714735251
          let codeviewHtml = $(this).summernote('code');
          $(this).val(codeviewHtml);
        },
      },
      prettifyHtml: true,
      codemirror: {
        htmlMode: true,
        lineWrapping: true,
        lineNumbers: true,
        mode: 'text/html'
      }
    });
  }

  // Expanded height text editor
  var expandedEditors = $('.js-textEditor-expanded');
  for (var i=0; i<expandedEditors.length; i++){
    var $expandedNote = $(expandedEditors[i]);
    $expandedNote.summernote({
      height: 400,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']],
      ],
      callbacks: {
        onImageUpload: function(files){
          summernoteImageUpload(files, $expandedNote);
        },
        onImageLinkInsert: function (url) {
          insertDefaultWidthImage(url, $expandedNote);
        },
        onBlurCodeview: function () {
          let codeviewHtml = $(this).summernote('code');
          $(this).val(codeviewHtml);
        },
      },
      prettifyHtml: true,
      codemirror: {
        htmlMode: true,
        lineWrapping: true,
        lineNumbers: true,
        mode: 'text/html'
      }
    });

    //modify the video node that summernote creates and make it responsive with bootstrap classes
    var nativeVideoNodeBuilderFunc = $expandedNote.summernote('module', 'videoDialog').createVideoNode;
    $expandedNote.summernote('module', 'videoDialog').createVideoNode = function(url) {
      url = url.replace(/\&.*/,'');
      var tmp = nativeVideoNodeBuilderFunc(url);
      var $embed = $('<p><br></p>');
      var $iframeWrapper = $('<div>').addClass('ratio ratio-16x9');
      $(tmp).appendTo($iframeWrapper);
      $iframeWrapper.prependTo($embed);
      return $embed[0];
    };
  }

  // Slim text editor
  var slimEditors = $('.js-textEditor-slim');
  for (var i=0; i<slimEditors.length; i++){
    var $slimNote = $(slimEditors[i]);
    $slimNote.summernote({
      height: 140,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture']],
        ['view', ['help']],
      ],
      callbacks: {
        onImageUpload: function(files){
          summernoteImageUpload(files, $slimNote);
        },
        onImageLinkInsert: function (url) {
          insertDefaultWidthImage(url, $slimNote);
        }
      }
    });
  }

});

function summernoteImageUpload(files, $summernote) {
  var file = files[0]; // TODO multiple images at once?
  // Get presign params and initiate upload
  $.ajax({
    url: '/s3/params',
    method: 'get',
    data: {
      filename: file.name,
      type: file.type
    },
    success: function(presignResponse){
      setTimeout(function() {
        sendSummernoteFileToS3(file, presignResponse, $summernote);
      }, 0);
    }
  });
}

function sendSummernoteFileToS3(file, presignResponse, $summernote){
  // Presign succeeded, package file to send via AJAX
  var presignFileData = presignResponse['fields'];
  var formData = new FormData();
  for (var key in presignFileData) {
    formData.append(key, presignFileData[key]);
  }
  formData.append("file", file);

  $.ajax({
    url: presignResponse['url'],
    method: presignResponse['method'],
    data: formData,
    processData: false,
    contentType: false,
    success: function(awsResponse) {
      // File has been successfully uploaded to S3!
      var url = presignResponse['url'] + (presignResponse['url'].endsWith('/') ? "" : "/") + presignFileData['key']; 

      // Replace the AWS URL with CloudFront URL
      var fullCloudFrontUrl = url.replace(awsUrl, cloudfrontUrl);

      // Insert the CloudFront URL into the editor
      insertDefaultWidthImage(fullCloudFrontUrl, $summernote);
    }
  });
}

function insertDefaultWidthImage(url, $summernote) {
  var $img = $('<img>').attr({ src: url, style: "max-width: 100%;" });
  $summernote.summernote('insertNode', $img[0]);
}

$(document).on('cocoon:after-insert', function (e, $insertedItem) {

  var slimEditors = $insertedItem.find('.js-textEditor-slim');
  for (var i=0; i<slimEditors.length; i++){
    var $summernote = $(slimEditors[i]);
    $summernote.summernote({
      height: 140,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture']],
        ['view', ['help']],
      ],
      callbacks: {
        onImageUpload: function(files){
          summernoteImageUpload(files, $summernote);
        }
      }
    });
  }

});
