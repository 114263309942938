$(document).on('turbolinks:load', function () {

  // error handling of bug that isn't clearing/hiding the discount code field on all browsers

  if ($('#discount_code').length) {
    $('#discount_code').val('').hide();
  }

  // filter out spaces when pasting into discount code field

  $('#discount_code').on('paste', function(e) {
    e.preventDefault();

    let pastedText = (e.originalEvent || e).clipboardData.getData('text/plain')
      .replace(/\s+/g, '')
      .replace(/[^\x20-\x7E]/g, '');

    const input = $(this)[0];
    
    input.setRangeText(
      pastedText,
      input.selectionStart,
      input.selectionEnd,
      'end'
    );
  });

  // existing operations code

  $('#discount_code').hide();
	$('#discount_code').on('keyup', function(e){

		$('.apply-coupon').attr('href', '/carts/checkout/discount/'+$(this).val())

	}).on('keypress', function(e){

		// dont allow anything weird
    if (e.which==32 || e.which < 45 || (e.which > 57 && e.which < 65) || (e.which > 90 && e.which < 97) || e.which > 122 )
        return false;
	});

	$('a[data-bs-toggle-coupon]').on('click',function(e){
		e.preventDefault();

		$('.coupon-code-wrap, #discount_code').toggle('fast')
		$(this).closest('.fieldset').fadeOut().remove();
	});

  var $body = $('body');
  var $cart_total = $('#amount').val()
  $('#payment-form').on('submit', function(){
    $body.addClass('loading').prepend('<div class="loading-messages">' + 'Submitting order...' + '</div>');
  });

  if ($body.hasClass('layout-carts-checkout') && $cart_total > 0) {
    initPaypalCheckout(true);
  }

});

function initPaypalCheckout(active){
  if ($('body').hasClass('layout-carts-checkout') && active) {
    paypal.Buttons({
      fundingSource: paypal.FUNDING.PAYPAL,
      style: {
        color: 'blue',
        label: 'checkout'
      },
      createOrder: function(data, actions) {
        // Set up the transaction
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: $("#amount").val() / 100,
              currency_code: 'USD',
              breakdown: {
                item_total: {
                  value: $("#subtotal_amount").val() / 100,
                  currency_code: 'USD'
                },
                tax_total: {
                  value: $("#tax_amount").val() / 100,
                  currency_code: 'USD'
                }
              }
            },
            description: "Blender Market order",
            items: $('.js-order-item').map(function(i, item) {
              var $item = $(item);
              return {
                name: $item.data('title'),
                quantity: 1,
                description: $item.data('version'),
                unit_amount: {
                  value: $item.data('price') / 100,
                  currency_code: 'USD'
                },
                category: 'DIGITAL_GOODS'
              };
            }).toArray()
          }]
        });
      },
      onApprove: function(data, actions) {
        // Authorize the transaction
        actions.order.authorize().then(function(authorization) {
          $('body').addClass('loading').prepend('<div class="loading-messages">Please wait ...</div>');
          // Call server to validate and capture the transaction
          return $.ajax({
            type: 'POST',
            url: '/orders',
            data: {
              gateway: 'paypal',
              amount: $("#amount").val(),
              coupon_id: $("#coupon_id").val(),
              paypal_payment: authorization,
              subtotal_amount: $('#subtotal_amount').val()
            },
            dataType: 'json',
            success: function(response){
              location.replace(response['pickup']);
            },
            error: function(response){
              location.replace(response['checkout']);
            }
          });
        });
      }
    }).render('#paypal-button-container');
  }
}
