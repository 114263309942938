import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { checkProductFormCompletion } from '../../views/creator/products/form_sections';
import { transferFileElementOutOfPond } from '../../views/creator/products/version_form';

$(document).on('turbolinks:load', function () {

  // FilePond setup
  FilePond.registerPlugin(FilePondPluginImagePreview);
  FilePond.registerPlugin(FilePondPluginFileMetadata);
  FilePond.registerPlugin(FilePondPluginFileValidateType);
  FilePond.registerPlugin(FilePondPluginFileValidateSize);

  var $featuredImageInputs = $('.js-imageInputFeatured');
  $featuredImageInputs.each(function(index, el){
    var $el = $(el);
    var pond = FilePond.create(el,
    {
      maxFiles: 1,
      maxFileSize: '5MB',
      acceptedFileTypes: ['image/png', 'image/jpeg', 'image/gif'],
      allowMultiple: false,
      allowImagePreview: true,
      allowPaste: false,
      server: {
        load: loadFile,
        process: processFeaturedImage,
        revert: removeFeaturedImage,
        remove: removeFeaturedImage
      },
      files: $el.data('files')
    });
  });

  var $multiImageInputs = $('.js-imageInputMulti');
  var $addToTop = $('#js-image-add-to-top');
  $multiImageInputs.each(function(index, el){
    var $el = $(el);
    var imageInsertLocation = $addToTop.val() === 'true' ? 'before' : 'after';
    var pond = FilePond.create(el,
    {
      maxFileSize: '5MB',
      acceptedFileTypes: ['image/*'],
      allowMultiple: true,
      allowImagePreview: true,
      imagePreviewHeight: 200,
      itemInsertLocation: imageInsertLocation,
      allowReorder: true,
      allowPaste: false,
      server: {
        load: loadFile,
        process: processImageFile,
        revert: removeImage,
        remove: removeImage
      },
      files: $el.data('files'),
      onprocessfile: setImageOrder,
      onreorderfiles: reorderProductImages
    });

    function setImageOrder(){
      reorderProductImages(pond.getFiles());
    };
  });

  var $downloadInputs = $('.js-fileInputDownload');
  $downloadInputs.each(function(index, el){
    var pond = FilePond.create(el,
    {
      allowMultiple: true,
      allowRevert: false,
      allowRemove: false,
      allowPaste: false,
      server: {
        process: processDownloadFile
      }
    });
  });

  var $versionDownloadInputs = $('.js-versionFileInputDownload');
  $versionDownloadInputs.each(function(index, el){
    var pond = FilePond.create(el,
    {
      allowMultiple: true,
      allowRevert: false,
      allowRemove: false,
      allowPaste: false,
      server: {
        process: processVersionDownloadFile
      }
    });
  });

  var $avatarInputs = $('.js-imageInputAvatar');
  $avatarInputs.each(function(index, el){
    var $el = $(el);
    var pond = FilePond.create(el,
    {
      maxFileSize: '5MB',
      acceptedFileTypes: ['image/*'],
      maxFiles: 1,
      allowMultiple: false,
      allowImagePreview: true,
      server: {
        load: loadFile,
        process: processAvatarImage,
        revert: removeAvatarImage,
        remove: removeAvatarImage
      },
      files: $el.data('files')
    });
  });

  var $messageInputs = $('.js-fileInputMessage');
  $messageInputs.each(function(index, el){
    var pond = FilePond.create(el,
    {
      maxFileSize: '5MB',
      maxFiles: 1,
      allowMultiple: false,
      allowRevert: false,
      allowRemove: false,
      server: {
        process: processMessageFile
      }
    });
  });

  var $postImageInputs = $('.js-imageInputPost');
  $postImageInputs.each(function(index, el){
    var $el = $(el);
    var pond = FilePond.create(el,
    {
      maxFiles: 1,
      maxFileSize: '5MB',
      acceptedFileTypes: ['image/png', 'image/jpeg', 'image/gif'],
      allowMultiple: false,
      allowImagePreview: true,
      server: {
        load: loadFile,
        process: processPostImage,
        revert: removePostImage,
        remove: removePostImage
      },
      files: $el.data('files')
    });
  });

  var $shopBannerInputs = $('.js-imageInputBanner');
  $shopBannerInputs.each(function(index, el){
    var $el = $(el);
    var pond = FilePond.create(el,
    {
      maxFiles: 1,
      maxFileSize: '5MB',
      acceptedFileTypes: ['image/png', 'image/jpeg', 'image/gif'],
      allowMultiple: false,
      allowImagePreview: true,
      server: {
        load: loadFile,
        process: processBannerImage,
        revert: removeBannerImage,
        remove: removeBannerImage
      },
      files: $el.data('files')
    });
  });

});

function loadFile(source, load, error, progress, abort, headers) {
  $.ajax({
    url: source.split("|")[1], // [0] is the database ID
    method: 'get',
    cache: false, // avoid S3 cors issues
    xhrFields: {
      responseType: 'blob'
    },
    success: function(response){
      load(response);
    }
  })
}

//
// 1. PROCESS
//
function processImageFile(fieldName, file, metadata, load, error, progress, abort) {
  // Begin processing image by sending a request to the server
  // Subsequent methods are handled in 2 callbacks: sendFileToS3, createProductImage
  // SetTimeout causes this to be started in a thread, which helps with animations
  setTimeout(getPresignParams(file, load, error, createProductImage), 0);
}

function processFeaturedImage(fieldName, file, metadata, load, error, progress, abort) {
  setTimeout(getPresignParams(file, load, error, updateProductFeaturedImage), 0);
}

function processDownloadFile(fieldName, file, metadata, load, error, progress, abort) {
  setTimeout(getPresignParams(file, load, error, createProductDownload), 0);
}

function processVersionDownloadFile(fieldName, file, metadata, load, error, progress, abort) {
  setTimeout(getPresignParams(file, load, error, createProductVersionDownload), 0);
}

function processAvatarImage(fieldName, file, metadata, load, error, progress, abort) {
  setTimeout(getPresignParams(file, load, error, updateUserAvatarImage), 0);
}

function processMessageFile(fieldName, file, metadata, load, error, progress, abort) {
  setTimeout(getPresignParams(file, load, error, assignMessageFiles), 0);
}

function processPostImage(fieldName, file, metadata, load, error, progress, abort) {
  setTimeout(getPresignParams(file, load, error, updatePostImage), 0);
}

function processBannerImage(fieldName, file, metadata, load, error, progress, abort) {
  setTimeout(getPresignParams(file, load, error, updateShopBannerImage), 0);
}

//
// 2. PRESIGN
//
function getPresignParams(file, load, error, callbackMethod) {
  // Server should presign the file and return params for S3
  $.ajax({
    url: '/s3/params',
    method: 'get',
    data: {
      filename: file.name,
      type: file.type
    },
    success: function(presignResponse){
      setTimeout(sendFileToS3(file, presignResponse, load, error, callbackMethod), 0);
    }
  });
}

//
// 3. S3 Storage
//
function sendFileToS3(file, presignResponse, load, error, callbackMethod){
  // Presign succeeded, package file to send via AJAX
  var presignFileData = presignResponse['fields'];
  var formData = new FormData();
  for (var key in presignFileData) {
    formData.append(key, presignFileData[key]);
  }
  formData.append("file", file);

  // Upload file to S3
  $.ajax({
    url: presignResponse['url'],
    method: presignResponse['method'],
    data: formData,
    processData: false,
    contentType: false,
    success: function(awsResponse){
      // File has been successfully uploaded to S3!
      // Now we have to inform our server of the upload.
      setTimeout(callbackMethod(file, presignFileData, load, error), 0);
    }
  });
}

//
// 4. APP HOOKUPS
//

function formatCachedUpload(file, presignFileData) {
  return {
    id: presignFileData['key'].match(/^cache\/(.+)/)[1], // object key without cache prefix
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    }
  };
}

// This method takes the CSRF token from a form and submits it remotely
function ajaxUpdateForm(formSelector, requestMethod, data, successCallback) {
  var $form = $(formSelector);
  var formData = {
    _method: requestMethod,
    authenticity_token: $form.find('input[name="authenticity_token"]').val()
  };
  $.ajax({
    url: $form.attr('action'),
    method: 'post',
    dataType: 'json',
    data: {
      ...formData,
      ...data
    },
    success: successCallback
  });
}

function updateProductFeaturedImage(file, presignFileData, load, error) {
  // Construct uploaded file data in the format that Shrine expects
  var uploadedFileData = formatCachedUpload(file, presignFileData);

  // Make an AJAX request to update our server with the new image
  ajaxUpdateForm(
    '.edit_product',
    'patch',
    { product: { image: uploadedFileData } },
    function(response){
      if (response['success'] == true){
        load(uploadedFileData['id']);
        checkProductFormCompletion(); // update form circles
      } else {
        error('Something went wrong');
        checkProductFormCompletion();
      }
    }
  );
}

function createProductImage(file, presignFileData, load, error) {
  var uploadedFileData = formatCachedUpload(file, presignFileData);

  ajaxUpdateForm(
    '.edit_product',
    'patch',
    { product: { images_attributes: [{ id: null, image: uploadedFileData }] } },
    function(response){
      // Tell FilePond to finish. last_image_id is used to associate the image
      // to the newly created ProductImage in the back end.
      if (response['success'] == true){
        load(response['last_image_id'] + "|" + uploadedFileData['id']);
      } else {
        error('Something went wrong');
      }
    }
  );
}

function createProductDownload(file, presignFileData, load, error) {
  // Construct uploaded file data in the format that Shrine expects
  var uploadedFileData = formatCachedUpload(file, presignFileData);

  // Make an AJAX request to update our server with the new image
  ajaxUpdateForm(
    '.edit_product',
    'patch',
    { product: { downloads_attributes: [{ id: null, title: file.name, file: uploadedFileData }] } },
    function(response){
      // Tell FilePond to finish. This ID doesn't matter, we will not use FP to revert downloads.
      if (response['success'] == true){
        load(uploadedFileData['id']);
      } else {
        error('Something went wrong');
      }
    }
  );
}

function createProductVersionDownload(file, presignFileData, load, error) {
  // Construct uploaded file data in the format that Shrine expects
  var uploadedFileData = formatCachedUpload(file, presignFileData);

  // Make an AJAX request to update our server with the new product file
  ajaxUpdateForm(
    '.new_download',
    'post',
    { download: { id: null, title: file.name, file: uploadedFileData } },
    function(response){
      // Tell FilePond to finish and create html element for response data
      if (response['success'] == true){
        load(uploadedFileData['id']);
        var pond = FilePond.find($('.js-versionFileInputDownload')[0]);

        setTimeout(function() {
          transferFileElementOutOfPond(pond, file, response['html']);
        }, 2000);

      } else {
        error('Something went wrong');
      }
    }
  );

}


function updateUserAvatarImage(file, presignFileData, load, error) {
  // Construct uploaded file data in the format that Shrine expects
  var uploadedFileData = formatCachedUpload(file, presignFileData);

  // Make an AJAX request to update our server with the new image
  ajaxUpdateForm(
    '.edit_user',
    'patch',
    { user: { avatar: uploadedFileData } },
    function(response){
      if (response['success'] == true){
        load(uploadedFileData['id']);
      } else {
        error('Something went wrong');
      }
    }
  );
}

function assignMessageFiles(file, presignFileData, load, error) {
  // Construct uploaded file data in the format that Shrine expects
  var uploadedFileData = formatCachedUpload(file, presignFileData);
  // Put it in a hidden field to be used on form submit
  $('.js-fileInputMessage-target').val(JSON.stringify(uploadedFileData));
  load(uploadedFileData['id']);
}

function updatePostImage(file, presignFileData, load, error) {
  // Construct uploaded file data in the format that Shrine expects
  var uploadedFileData = formatCachedUpload(file, presignFileData);

  // Make an AJAX request to update our server with the new image
  ajaxUpdateForm(
    '.edit_post',
    'patch',
    { post: { image: uploadedFileData } },
    function(response){
      if (response['success'] == true){
        load(uploadedFileData['id']);
      } else {
        error('Something went wrong');
      }
    }
  );
}

function updateShopBannerImage(file, presignFileData, load, error) {
  // Construct uploaded file data in the format that Shrine expects
  var uploadedFileData = formatCachedUpload(file, presignFileData);

  //the controller expects a settings id, otherwise it will create a new ShopSetting
  var $settingsId = $('input[name="shop[settings_attributes][id]"]').val()

  // Make an AJAX request to update our server with the new image
  ajaxUpdateForm(
    '.edit_shop',
    'patch',
    { 
      shop: {
        settings_attributes: { 
          id: $settingsId,
          banner: uploadedFileData 
        }
      } 
    },
    function(response){
      if (response['success'] == true){
        load(uploadedFileData['id']);
      } else {
        error('Something went wrong');
      }
    }
  );
}

//
// 4a. IMAGE GALLERY REORDERING
//

// This only works with 1 multiple image gallery at a time due to the global variable.
// If needed we can refactor this later to be more flexible.
var temporaryImageReorderTimeout;
function reorderProductImages(filesArray) {
  // Set a timeout. After 5 seconds of no activity, let's update the server
  clearTimeout(temporaryImageReorderTimeout);
  temporaryImageReorderTimeout = setTimeout(function(){
    updateProductImageOrdinals(filesArray);
  }, 5000);
}

function updateProductImageOrdinals(filesArray) {
  var formattedData = [];
  for (var i=0; i<filesArray.length; i++){
    formattedData.push({
      id: filesArray[i].serverId.split("|")[0],
      ordinal: i
    });
  }

  ajaxUpdateForm(
    '.edit_product',
    'patch',
    { product: { images_attributes: formattedData } },
    function(response){
      // pass
    }
  );
}

//
// 5. REMOVE / REVERT
//
function removeFeaturedImage(source, load, error) {
  // Make an AJAX request to delete the image
  ajaxUpdateForm(
    '.edit_product',
    'patch',
    { product: { remove_image: true } },
    function(response){
      if (response['success'] == true){
        load();
        setTimeout(checkProductFormCompletion, 300);
      } else {
        error('File could not be removed');
        checkProductFormCompletion();
      }
    }
  );
}

function removeImage(source, load, error) {
  var productImageID = source.split("|")[0];

  // Make an AJAX request to delete the image
  ajaxUpdateForm(
    '.edit_product',
    'patch',
    { product: { destroy_product_images: [productImageID] } },
    function(response){
      if (response['success'] == true){
        load();
      } else {
        error('File could not be removed');
      }
    }
  );
}

function removeAvatarImage(source, load, error) {
  // Make an AJAX request to delete the image
  ajaxUpdateForm(
    '.edit_user',
    'patch',
    { user: { remove_avatar: true } },
    function(response){
      if (response['success'] == true){
        load();
      } else {
        error('File could not be removed');
      }
    }
  );
}

function removePostImage(source, load, error) {
  // Make an AJAX request to delete the image
  ajaxUpdateForm(
    '.edit_post',
    'patch',
    { post: { remove_image: true } },
    function(response){
      if (response['success'] == true){
        load();
        setTimeout(checkProductFormCompletion, 300);
      } else {
        error('File could not be removed');
        checkProductFormCompletion();
      }
    }
  );
}

function removeBannerImage(source, load, error) {
  var $settingsId = $('input[name="shop[settings_attributes][id]"]').val()

  // Make an AJAX request to delete the image
  ajaxUpdateForm(
    '.edit_shop',
    'patch',
    { 
      shop: {
        settings_attributes: { 
          id: $settingsId,
          remove_banner: true
        }
      } 
    },
    function(response){
      if (response['success'] == true){
        load();
      } else {
        error('File could not be removed');
      }
    }
  );
}
